<template>
    <div :class="['container']">
        <div class="left">
            <video class="videoElement" ref="videoElement" autoplay muted></video>
        </div>
        <div class="center" :style="'background-color: ' + color">
            <div class="time">{{ time }}</div>

            <button style="width: 80%; margin-top: 5%;" :class="buttonClasses[0]" @click="handleButtonClick(1)">{{
                buttonTexts[0] }}</button>
            <button style="width: 80%; margin-top: 5%;" :class="buttonClasses[1]" @click="handleButtonClick(2)">{{
                buttonTexts[1] }}</button>
            <button style="width: 80%; margin-top: 5%;" :class="buttonClasses[2]" @click="handleButtonClick(3)">{{
                buttonTexts[2] }}</button>

            <div style="margin-top: 5px;">{{ txt }}</div>
            <div style="margin-top: 5px;">
                <!-- <input type="checkbox" style="width: 30px; height: 30px;" @click="handleCheckboxClick" /> -->
            </div>
        </div>
        <div class="right">
            <div class="grid-item">
                <div class="grid-item-title">
                  余额
                </div>
                <div class="grid-item-content">
                    {{ gridItems[0].content }}
                </div>
            </div>
            <div class="grid-item">
                <div class="grid-item-title">
                  注额
                </div>
                <div class="grid-item-content">
                    {{ gridItems[1].content }}
                </div>
            </div>
            <div class="grid-item">
                <div class="grid-item-title">
                  赢额
                </div>
                <div class="grid-item-content">
                    {{ gridItems[2].content }}
                </div>
            </div>
            <div class="grid-item">
                <div class="grid-item-title">
                  闲对
                </div>
                <div class="grid-item-content">
                    {{ gridItems[3].content }}
                </div>
            </div>

            <div class="grid-item">
                <button style="height: 80%;width: 80%;margin-top: 5%;font-size: 15px;"
                    :class="[buttonClasses[4], { 'highlight': maxKey === 6 }]"
                    @click="handleButtonClick(4)">和</button>
            </div>

            <div class="grid-item">
                <div class="grid-item-title">
                  庄对
                </div>
                <div class="grid-item-content">
                    {{ gridItems[4].content }}
                </div>
            </div>
            <div class="grid-item">
                <div class="grid-item-title">
                  闲
                </div>
                <div class="grid-item-content">
                    {{ gridItems[5].content }}
                </div>
            </div>
            <div class="grid-item">
                <div class="grid-item-title">
                   和
                </div>
                <div class="grid-item-content">
                    {{ gridItems[6].content }}
                </div>
            </div>
            <div class="grid-item">
                <div class="grid-item-title">
                    庄
                </div>
                <div class="grid-item-content">
                    {{ gridItems[7].content }}
                </div>
            </div>

            <div class="grid-item">
                <button style="height: 80%;width: 80%;margin-top: 5%;font-size: 15px;"
                    :class="[buttonClasses[4], { 'highlight': maxKey === 5 }]"
                    @click="handleButtonClick(5)">庄</button>
            </div>
            <div class="grid-item">
                <button style="height: 80%;width: 80%;margin-top: 5%;font-size: 15px;"
                        :class="buttonClasses[5]"
                    @click="handleButtonClick(6)">撤销</button>
            </div>
            <div class="grid-item">
                <button style="height: 80%;width: 80%;margin-top: 5%;font-size: 15px;"
                    :class="[buttonClasses[5], { 'highlight': maxKey === 7 }]"
                    @click="handleButtonClick(7)">闲</button>
            </div>


        </div>
    </div>
</template>
<script>
import flvjs from "flv.js";
// import Mock from 'mockjs';
// import mitt from 'mitt';

export default {
    name: "VideoComponent",
    props: {
        id: {
            type: String,
            default: "",
        },
        // txt: {
        //     type: String,
        //     default: "",
        // },
    },
    data() {
        return {
            time: 25,
            maxKey: 0,
            txt: '',
            gridItems: [
                { title: '余额', content: '内容1' },
                { title: '注额', content: '内容2' },
                { title: '赢额', content: '内容3' },
                { title: '闲对', content: '内容4' },
                { title: '庄对', content: '内容5' },
                { title: '闲', content: '内容6' },
                { title: '和', content: '内容7' },
                { title: '庄', content: '内容8' },
                // ...其他 grid-item 数据...
            ],
            gridItemsLIST: [
                [
                    { title: '标题1', content: '内容1' },
                    { title: '标题2', content: '内容2' },
                    { title: '标题3', content: '内容3' },
                    { title: '标题4', content: '内容4' },
                    { title: '标题5', content: '内容5' },
                    { title: '标题6', content: '内容6' },
                    { title: '标题7', content: '内容7' },
                    { title: '标题8', content: '内容8' },
                ],
                [
                    { title: '标题1', content: '内容1' },
                    { title: '标题2', content: '内容2' },
                    { title: '标题3', content: '内容3' },
                    { title: '标题4', content: '内容4' },
                    { title: '标题5', content: '内容5' },
                    { title: '标题6', content: '内容6' },
                    { title: '标题7', content: '内容7' },
                    { title: '标题8', content: '内容8' },
                ]
                // ...其他 grid-item 数据...
            ],
            buttonTexts: [
                "开始",
                "刷新",
                '成功',
                // 'button is-small is-warning',
                // 'button is-small is-warning',
                // 'button is-small is-warning',
                // 'button is-small is-warning'
            ],
            color: "rgb(34, 34, 34)",
            buttonClasses: [
                'button is-small',
                'button is-small',
                'button is-small',
                'button is-small is-warning',
                'button is-small is-warning',
                'button is-small is-warning',
                'button is-small is-warning'
            ],
            timer: null,
        };
    },
    created() {
        // this.setupMock();
        // this.fetchData();
        // const emitter = mitt();
    },
    mounted() {
        this.fetchData();
        this.emitter.on('video', this.handleSomeEvent);
        // document.addEventListener('click', this.handleDocumentClick);
    },
    beforeUnmount() {
        if (this.flvPlayer) {
            this.flvPlayer.destroy();
        }
        // document.removeEventListener('click', this.handleDocumentClick);
        this.emitter.off('video', this.handleSomeEvent);
    },

    methods: {
        handleDocumentClick() {
            console.log(1);
            return;

            // document.removeEventListener('click', this.handleDocumentClick);
            // this.flvPlayer.play();
            // console.log('Document clicked');
        },
        handleButtonClick(buttonIndex) {
            if (buttonIndex == 1) {
                if (this.flvPlayer) {
                    this.flvPlayer.destroy();
                }
                this.fetchData()
                // document.addEventListener('click', this.handleDocumentClick);
            }
            const id = this.id;
            console.log(`按钮${buttonIndex}被点击`);
            fetch('https://mock.presstime.cn/mock/675ac79745bad9c3dd7b5013/test01/send?id=' + id + "&buttonIndex=" + buttonIndex, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ buttonIndex })
            })
            // 根据 buttonIndex 处理不同的点击事件
        },
        handleCheckboxClick(event) {
            const id = this.id;
            const isChecked = event.target.checked;//是勾选还是不勾选
            fetch('https://mock.presstime.cn/mock/675ac79745bad9c3dd7b5013/test01/send?id=' + id + "&buttonIndex=100", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ buttonIndex: 100, checked: isChecked })
            });
        },
        startTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
        },
        setTimer(value) {
            this.time = value;
            // 如果需要重新启动计时器，可以取消注释以下代码
            // clearInterval(this.timer);
            this.startTimer();
        },
        fetchData(callback) {
            const id = this.id;
            console.log('Component ID:', id);
            fetch('https://mock.presstime.cn/mock/675ac79745bad9c3dd7b5013/test01/video?id=' + id)
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    // 根据返回的数据更新组件状态
                    this.gridItems = data.data.gridItems;
                    this.gridItemsLIST[0] = data.data.gridItems;
                    this.gridItemsLIST[1] = data.data.gridItems1;

                    this.maxKey = this.getMaxValue()
                    this.time = data.data.time;
                    this.txt = data.data.txt;
                    var url = data.data.url;

                    if (flvjs.isSupported()) {
                        this.flvPlayer = flvjs.createPlayer({
                            type: 'flv',
                            url: url,
                            isLive: true,
                            hasAudio: false,

                        });
                        this.flvPlayer.attachMediaElement(this.$refs.videoElement);
                        this.flvPlayer.load();

                        setTimeout(() => {
                            // this.flvPlayer.play();//延迟一秒钟后播放
                        }, 1000);
                        // callback
                        if (callback) {
                            callback();
                        }
                        try {
                            // flvPlayer.play();
                        } catch (error) {
                            // console.log(error);

                        }
                    }

                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

            this.setTimer(99);
            // this.startTimer();
        },
        getMaxValue() {
            var keys = [5, 6, 7];

            var obj = keys.map(key => {
                return {
                    key: key,
                    val: this.gridItems[key].content
                };
            });
            var maxValue = 0;
            obj.forEach(item => {
                if (item.val > maxValue) {
                    maxValue = item.val;
                }
            });

            return obj.find(item => item.val === maxValue).key;


        },
        handleSomeEvent(e) {
            if (e.videoId == this.id) {
                if (e.type == 'txt') {
                    this.buttonTexts[e.id] = e.txt;
                    console.log('处理事件:', e, this.id);
                }
                if (e.type == 'class') {
                    this.buttonClasses[e.id] = e.class;
                    console.log('处理事件:', e, this.id);
                }

                if (e.type == 'play') {
                    var url = e.url;
                    this.flvPlayer.destroy();
                    this.flvPlayer = flvjs.createPlayer({
                        type: 'flv',
                        url: url,
                        isLive: true,
                        hasAudio: false,

                    });
                    this.flvPlayer.attachMediaElement(this.$refs.videoElement);
                    this.flvPlayer.load();
                }

                if (e.type == 'txt1') {
                    this.gridItems[e.id - 1].title = e.txt;
                    console.log('处理事件:', e, this.id);
                }

                if (e.type == 'txt2') {
                    this.gridItems[e.id - 1].content = e.txt;
                    console.log('处理事件:', e, this.id);
                }
                if (e.type == 'color') {
                    // this.gridItems[e.id-1].content = e.txt;
                    console.log('处理事件:', e, this.id);
                }

            }
            // console.log('处理事件:', this.id);
            // 根据事件数据更新组件状态或执行其他操作
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    display: flex;
    height: 25vh;
    width: 100%;
    background-color: #942e2e;

    border: 1px solid #949494;
    box-sizing: border-box;
    overflow-y: hidden;
}

.time {
    // margin-top: 10px;
    display: inline-block;
    border: 3px solid rgb(68, 71, 14);
    color: rgb(255, 0, 0);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    line-height: 46px;
    background-color: #bda000;
    font-weight: 600;
    font-size: 30px;
}

.left {
    // flex: 1.4;
    width: 25vh * 1.78;
    height: 100%;
    // width: 25vh;
}

.right {
    height: 100%;
    flex: 1;
}

.left {
    background-color: #942e2e;
}

.right {
    background-color: #000000;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
}

.center {
    height: 100%;
    text-align: center;
    width: 70px;
    background-color: rgb(34, 34, 34);
}

.videoElement {
    height: 100%;
    width: 100%;
}

.grid-item {
    height: 25%;
    width: 33.333333333333333333%;
    border: 1px solid #b3b3b3;
    box-sizing: border-box;

    .grid-item-title {
        padding: 0;
        font-size: 14px;
        font-weight: bold;
        box-sizing: border-box;
        border-bottom: 1px solid #616161;
        background-color: #a6af20;
        color: #000000;
    }

    .grid-item-content {
        font-size: 15px;
        color: #fff;
        height: calc(75% - 2px);
        padding-top: 2px;
        // line-height: calc(75% - 2px);
    }
}

.highlight {
    // background-color: rgb(0, 0, 0);
    // color: #fff;
    background-color: #f00;
}
</style>
