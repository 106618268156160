import { createApp } from "vue";
import App from "./App.vue";
import mitt from "mitt";

/* Bulma Import */
import "bulma/css/bulma.css";
// import flvjs from "flv.js";

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
window.emitter = emitter;

//修改按钮内文字
setInterval(() => {
  emitter.emit("video", {
    videoId: 1, //第几个视频
    type: "txt", //修改文字
    id: 2, //第几个按钮
    txt: "修改按钮",
  });
}, 3000);


// 修改按钮颜色
setInterval(() => {
  emitter.emit("video", {
    videoId: 1, //第几个视频
    type: "class", //修改样式  也就是修改颜色
    id: 2, //第几个按钮
    class: "button is-small highlight", //highlight 代表高亮  去掉highlight 为普通默认
  });
}, 6000);

//修改倒计时
setInterval(() => {
  emitter.emit("video", {
    videoId: 1, //第几个视频
    type: "time", //类型是什么  time 是倒计时
    time: 100, //时间
  });
}, 10000);

// 修改文本框 标题
emitter.emit("video", {
  videoId: 1, //第几个视频
  type: "txt1", //
  id: 2, //第几个
  txt: "修改",
});

// 修改文本框 内容
emitter.emit("video", {
  videoId: 1, //第几个视频
  type: "txt2", //
  id: 2, //第几个
  txt: "修改",
});


// 中间颜色
emitter.emit("video", {
  videoId: 1, //第几个视频
  type: "color", //
  color: "#ccc",
});

// {"videoId":1,"type":"play","url":"播放地址"}
//{"videoId":1,"type":"time","time":100}
//{"id":1,"data":[1,2,3],"type":"data"}
// recv{
//   A格子数据[1][1,2,3,1,1,12,3,3,]
// A格子数据[2][1,2,3,1,1,12,3,3,]
// A格子数据[3][1,2,3,1,1,12,3,3,]
// A格子数据[4][1,2,3,1,1,12,3,3,]
// A格子数据[5][1,2,3,1,1,12,3,3,]
// }

// recv{
//   B格子数据[1][1,2,3,1,1,12,3,3,]
// B格子数据[2][1,2,3,1,1,12,3,3,]
// B格子数据[3][1,2,3,1,1,12,3,3,]
// B格子数据[4][1,2,3,1,1,12,3,3,]
// B格子数据[5][1,2,3,1,1,12,3,3,]
// }
//这些是上面表格的
// emitter.emit("table1", { id: 1, data: [1, 2, 3, 4], type: "data" }); //发送1
// emitter.emit("table1", { id: 1, data: [], type: "data" });  传空数据 代表清空
// emitter.emit("table1", { id: 2, data: [1, 2, 3, 4], type: "data" }); //发送2
// emitter.emit("table1", { id: 3, data: [1, 2, 3, 4], type: "data" }); //发送3
// emitter.emit("table1", { id: 4, data: [1, 2, 3, 4], type: "data" }); //发送4
// emitter.emit("table1", { id: 5, data: [1, 2, 3, 4], type: "data" }); //发送5
// emitter.emit("table1", { id: 6, data: [1, 2, 3, 4], type: "data" }); //发送6

// emitter.emit("table1", { id: 6, color: "#f00", type: "color" }); //发送第六个为红色

//这些是下面表格的
// emitter.emit("table2", { id: 1, data: [1, 2, 3, 4], type: "data" }); //发送1

app.mount("#app");
