<template>
    <div class="content-left">
        <table>
            <tr class="table-title">
                <th>账号</th>
                <th>余额</th>
                <th>赢额</th>
                <th></th>
            </tr>
            <tr v-for="(row, index) in rows" :key="index">
                <td>{{ row.value1 }}</td>
                <td>{{ row.value2 }}</td>
                <td
                    :style="{ color: row.value3 > 0 ? 'white' : 'inherit', backgroundColor: row.value3 > 0 ? 'red' : 'inherit' }">
                    {{ row.value3 }}</td>

                <td style="width: 20px;padding-top: 8px;">
                    <input type="checkbox" style="width: 25px; height: 25px;" :checked="row.c"
                        @click="handleCheckboxChange(index)" />
                    <!-- <button @click="handleClick(row)">Click Me</button> -->
                </td>

            </tr>
        </table>
    </div>
</template>
<script>

export default {
    name: 'ContentLeftComponent',
    data() {
        return {
            rows: [],
            selectedI: -1,
            // selected: []
        }
    },
    computed: {

    },
    created() {
        this.fetchData();

    },
    methods: {
        handleCheckboxChange(index) {

            this.rows.forEach((row, i) => {
                if (i !== index) {
                    row.c = false;
                }
            });

            this.rows[index].c = !this.rows[index].c;
            this.selectedI = index;
            // if (this.selectedI !== index) {
            //     this.rows[index].c = true;
            // }

            // if (this.selected.includes(index)) {
            //     this.selected = this.selected.filter(i => i !== index);
            // } else {
            //     this.selected.push(index);
            // }

            alert("点击了第" + (index + 1) + "行");
        },

        fetchData() {
            fetch(' https://mock.presstime.cn/mock/675ac79745bad9c3dd7b5013/test01/left')
                .then(response => response.json())
                .then(data => {
                    this.rows = data.data.rows.map(item => ({
                        value1: item.value1,
                        value2: item.value2,
                        value3: item.value3,
                        c: false,
                    })).sort((a, b) => b.value3 - a.value3);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }
}
</script>
<style lang="">

</style>


<style lang="less" scoped>
.content {
    display: flex;
    height: 100%;
    flex-direction: row;
}

.content-left {
    // width: 350px;
    // padding-right: 10px;
    height: 100%;
    overflow-y: auto;
    background-color: #000000;
    // color: #fff;

    table {
        width: 100%;
        height: 100%;
        border-collapse: collapse;

        .table-title {
            font-size: 10px;
            // height: 50px;
            background-color: #a6af20;

            th {
                padding: 0;
                color: #000;

            }
        }
    }

    th,
    td {
        height: 35px;
        line-height: 35px;
        font-size: 15px;
        border: 3px solid #777777;
        text-align: center;
        // padding-top: 3px;
        // padding: 8px;
    }

    th {

        font-size: 18px;
        height: 25px;
        line-height: 25px;
    }
}

.content-right {
    flex: 1;
    height: 100%;
    background-color: #e74c3c;
}
</style>